// The route import schema object defines the component import scheme for all
// the routes in the application. This information can then be used to extend
// the route metadata (by an operation inside the route manager, defined in
// `/routes/index.js` ) that asserts the actual order in which the routes are
// read by the routing component.
export default {
  base: {
    load: () => import(/* webpackChunkName: 'base' */ './base'),
  },
  '/dashboard': {
    load: false,
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
      },
      {
        path: '/:queryName',
        load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
      },
    ],
  },
  '/applications': {
    children: [
      {
        path: '',
        load: () =>
          import(/* webpackChunkName: 'applications' */ './applications'),
      },
      {
        path: '/:application',
        load: () =>
          import(/* webpackChunkName: 'applications' */ './applications'),
      },
      {
        path: '/:application/:version',
        load: () =>
          import(/* webpackChunkName: 'applications' */ './applications'),
      },
    ],
  },
  '/sboms': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'sboms' */ './sboms'),
      },
      {
        path: '/groups/:groupId',
        load: () => import(/* webpackChunkName: 'sboms' */ './sboms'),
      },
      {
        path: '/:sbomId',
        load: () => import(/* webpackChunkName: 'sboms' */ './sboms'),
      },
      {
        path: '/:sbomId/:revisionId',
        load: () => import(/* webpackChunkName: 'sboms' */ './sboms'),
      },
      {
        path: '/:sbomId/:revisionId/:detailTab',
        load: () => import(/* webpackChunkName: 'sboms' */ './sboms'),
      },
    ],
  },
  '/artifacts/source': {
    children: [
      {
        path: '/:id',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
    ],
  },
  '/artifacts/image': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
      {
        path: '/:registry',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
      {
        path: '/:registry/:repository',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
      {
        path: '/:registry/:repository/:tag',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
      {
        path: '/:registry/:repository/:tag/:id',
        load: () => import(/* webpackChunkName: 'artifacts' */ './artifacts'),
      },
    ],
  },
  '/policy': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'policy' */ './policy'),
      },
      {
        path: '/:policyId',
        load: () => import(/* webpackChunkName: 'policy' */ './policy'),
      },
    ],
  },
  '/inventory': {
    load: () => import(/* webpackChunkName: 'base' */ './base'),
  },
  '/inventory/kubernetes': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'inventory' */ './inventory'),
      },
      {
        path: '/:cluster',
        load: () => import(/* webpackChunkName: 'inventory' */ './inventory'),
      },
      {
        path: '/:cluster/:namespace',
        load: () => import(/* webpackChunkName: 'inventory' */ './inventory'),
      },
    ],
  },
  '/reports': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'reports' */ './reports'),
      },
      {
        path: '/saved',
        load: () => import(/* webpackChunkName: 'reports' */ './reports'),
      },
      {
        path: '/new',
        load: () => import(/* webpackChunkName: 'reports' */ './reports'),
      },
      {
        path: '/templates',
        load: () => import(/* webpackChunkName: 'reports' */ './reports'),
      },
      {
        path: '/saved/:reportId/result/:executionId',
        load: () => import(/* webpackChunkName: 'reports' */ './reports'),
      },
    ],
  },
  '/events': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'events' */ './events'),
      },
    ],
  },
  '/notifications': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'events' */ './events'),
      },
    ],
  },
  '/system': {
    children: [
      {
        path: '',
        load: () => import(/* webpackChunkName: 'system' */ './system'),
      },
      {
        path: '/:tab',
        load: () => import(/* webpackChunkName: 'system' */ './system'),
      },
      {
        path: '/accounts/:accountName',
        load: () => import(/* webpackChunkName: 'system' */ './system'),
      },
    ],
  },
  '(.*)': {
    load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
  },
};
